import React from 'react'

import Layout from '../components/layout'

export default () => {
    return (
        <React.Fragment>
            <Layout>
            <div>
                Sorry, 404 Not Found.
            </div>
            </Layout>
        </React.Fragment>
    )
}